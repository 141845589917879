var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "router-link",
                {
                  staticStyle: { "text-decoration": "none" },
                  attrs: { to: "/home" },
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "icon-home", attrs: { color: "#2D5796" } },
                    [_vm._v("mdi-home-outline")]
                  ),
                ],
                1
              ),
              _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                _vm._v("mdi-chevron-right"),
              ]),
              _c(
                "b",
                {
                  staticStyle: {
                    color: "#8d8d99",
                    "font-weight": "400",
                    "font-size": "14px",
                    "letter-spacing": "0.01em",
                  },
                },
                [_vm._v("e-CAC")]
              ),
              _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                _vm._v("mdi-chevron-right"),
              ]),
              _c(
                "router-link",
                {
                  staticStyle: {
                    "text-decoration": "none",
                    "font-size": "14px",
                    color: "#2D5796",
                  },
                  attrs: { to: "/ecac/mensagens" },
                },
                [_vm._v("Mensagens e-CAC")]
              ),
              _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                _vm._v("mdi-chevron-right"),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "text-link",
                  attrs: { to: "/cadastros-gerenciais/configuracoes-ecac" },
                },
                [_c("strong", [_vm._v("Configurações")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }